import BlankPage from './BlankPage';
import DashboardPage from './DashboardPage';
import LoginForm from './LoginForm';
import MyProfilePage from './MyProfilePage';
import MyReportPage from './MyReportPage';
import ChangePasswordPage from './ChangePasswordPage';
import NotFoundPage from './NotFoundPage';
import ForgotPasswordForm from './ForgotPasswordForm';
import Viewer from './Viewer';

import AdminContents from './admin';
import OperacoesContents from './operacoes';
import EventosContents from './eventos';
import GeoContents from './geo';
import DisqueDenunciaContents from './disquedenuncia';
import BensEValoresContents from './bensevalores';
import CautelaContents from "./cautela";
import FaceContents from "./faces";

import ContratosContents from "./contratos";
import DocumentosContents from './documentos';
import EleicoesContents from './eleicoes';
import AlertaParaContents from './alertapara';
import RelatorioContents from './relatorios';
import MapasContents from './mapas';
import BoletimContents from './boletins';


import AlertaEscolaContents from './alertaEscola';
import TrackerContents from './tracker';

import VeiculosContents from './veiculos';
import PppddhContents from './ppddh';

import ProMulherContents from './proMulher'
import GrupamentoFluvialContents from './grupamentoFluvial';

import ConsepContents from './consep';




const Contents = {
    Viewer,
    BlankPage,
    DashboardPage,
    LoginForm,
    MyProfilePage,
    MyReportPage,
    ChangePasswordPage,
    NotFoundPage,
    ForgotPasswordForm,
    ...AdminContents,
    ...EventosContents,
    ...GeoContents,
    ...OperacoesContents,
    ...DisqueDenunciaContents,
    ...BensEValoresContents,
    ...CautelaContents,
    ...FaceContents,
    ...ContratosContents,
    ...DocumentosContents,
    ...EleicoesContents,
    ...AlertaParaContents,
    ...RelatorioContents,
    ...MapasContents,
    ...BoletimContents,
    ...AlertaEscolaContents,
    ...TrackerContents,
    ...VeiculosContents,
    ...PppddhContents,
    ...ProMulherContents,
    ...GrupamentoFluvialContents,
    ...ConsepContents,
};

export default Contents;