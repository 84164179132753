import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';

function Map({ center, logradouro, numero }) {
    const RedrawMap = ({ center }) => {
        const map = useMap();
        useEffect(() => {
            map.invalidateSize();
            map.setView(center); // força o mapa a ajustar a visualização
        }, [center, map]);
        return null;
    };

    return (
        <MapContainer
            center={center}
            zoom={16}
            style={{ height: "500px", width: '100%' }}
            interactive={true}
        >
            <RedrawMap center={center} />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={center}>
                <Popup>
                    <p>{`Endereço: ${logradouro}, Nº: ${numero}`}</p>
                </Popup>
            </Marker>
        </MapContainer>
    );
}

export default Map;
