import DashboardGflu from "./DashboardGflu";
import EmbarcacaoList from "./EmbarcacaoList";
import GrupamentoFluvialForm from "./GrupamentoFluvialForm";
import ManutencaoForm from "./ManutencaoForm";
import ManutencaoList from "./ManutencaoList";
import RelatorioGfluForm from "./RelatorioGfluForm";
import RelatorioGfluList from "./RelatorioGfluList";

const GrupamentoFluvialContents = {
  EmbarcacaoList,
  GrupamentoFluvialForm,
  RelatorioGfluList,
  RelatorioGfluForm,
  ManutencaoList,
  ManutencaoForm,
  DashboardGflu,
};

export default GrupamentoFluvialContents;
