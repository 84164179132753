import React from "react";
import { AppContext } from "../../../context";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Col,
  Form as BSForm,
  Row,
  FormGroup,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

class GrupamentoFluvialForm extends React.Component {
  static contextType = AppContext;

  state = {
    risps: [],
    cidades: [],
    orgaos: [],
    setores: [],
    showModal: false,
    success: false,
  };

  save(values, callback) {
    const method = this.props.embarcacao.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/grupamentoFluvial/embarcacao" +
      (this.props.embarcacao.id ? "/" + this.props.embarcacao.id : "");
    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        this.context.setContent("EmbarcacaoList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/risp/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ risps: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);
  }

  render() {
    const statusEmbarcacao = [
      { value: "Ativa", label: "Ativa" },
      { value: "Inativa", label: "Inativa" },
    ];

    return (
      <Formik
        initialValues={{
          ...this.props.embarcacao,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.CidadeId) {
            errors.CidadeId = "Campo obrigatório";
          }
          if (!values.RispId) {
            errors.RispId = "Campo obrigatório";
          }
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.OrgaoId) {
            errors.OrgaoId = "Campo obrigatório";
          }
          if (!values.SetorId) {
            errors.SetorId = "Campo obrigatório";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, setValues }) => {
          const isStatusInativa = values.status === "Inativa";

          return (
            <>
              <Form>
                      <Col md={12}>
                        <Card className="mt-3">
                          <Card.Header>
                            <strong>Dados Básicos da Embarcação</strong>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={4} className="mt-2">
                                <BSForm.Label>Status</BSForm.Label>
                                <ErrorMessage
                                  name="status"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="status"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={statusEmbarcacao}
                                  value={statusEmbarcacao.find(
                                    (status) => status.value === values.status
                                  )}
                                  onChange={(option) => {
                                    setFieldValue("status", option.value);
                                  }}
                                />
                              </Col>

                              {isStatusInativa && (
                                <Col md={4} className="mt-2">
                                  <BSForm.Label>
                                    Data de Inatividade
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name="data_inatividade"
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="date"
                                    name="data_inatividade"
                                    className="form-control"
                                    value={values.data_inatividade}
                                  />
                                </Col>
                              )}

                              <Col md={4} className="mt-2">
                                <BSForm.Label>
                                  Regiões de Integração
                                </BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="Risps"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="Risps"
                                  noOptionsMessage={(text) =>
                                    text.inputValue + " não encontrado!"
                                  }
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={this.state.risps}
                                  value={this.state.risps.filter(
                                    (option) => values.RispId === option.value
                                  )}
                                  onChange={(options) => {
                                    setFieldValue(
                                      "RispId",
                                      options ? options.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Cidade</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="CidadeId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="CidadeId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.cidades}
                                  value={
                                    this.state.cidades
                                      ? this.state.cidades.find(
                                          (option) =>
                                            option.value === values.CidadeId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "CidadeId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Orgão</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="OrgaoId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="OrgaoId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.orgaos}
                                  value={
                                    this.state.orgaos
                                      ? this.state.orgaos.find(
                                          (option) =>
                                            option.value === values.OrgaoId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "OrgaoId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Setor</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="SetorId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="SetorId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.setores.filter(
                                    (setor) => setor.OrgaoId === values.OrgaoId
                                  )}
                                  value={
                                    this.state.setores
                                      ? this.state.setores.find(
                                          (option) =>
                                            option.value === values.SetorId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "SetorId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Nome</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="nome"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="nome"
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} className="mt-2">
                                <BSForm.Label>
                                  Registro Patrimonial
                                </BSForm.Label>
                                <ErrorMessage
                                  name="registro_patrimonial"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="registro_patrimonial"
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                <FormGroup className="text-right mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      this.context.setContent("EmbarcacaoList");
                      setValues({
                        ...values,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Fechar
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default GrupamentoFluvialForm;
