import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";
import Select from 'react-select';
class DenunciasArquivadasModal extends React.Component {

    static contextType = AppContext;

    state = {
        atendentes: []

    }

    componentDidMount() {

        const errorHandler = err => this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na pesquisa: " + err.toString() });

        Request('GET', this.context.config.BACKEND_URL + '/usuario/disquedenuncia/options', this.context.token).send()
            .then(options => {
                this.setState(() => ({ atendentes: options.body }));
            })
            .catch(errorHandler);
    }

    save(values, callback) {

        const url = this.context.config.BACKEND_URL + '/denuncia/arquivadas/' + this.props.arquivadas.id;
        Request("PUT", url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({
                    titulo: "Successo",
                    conteudo: "Registro atualizado com sucesso."
                });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();;
            })
            .catch(err => {

                this.context.addToast({
                    titulo: "Erro",
                    conteudo: "Houve uma falha na gravação do registro."
                });
                callback();
            });
    }


    render() {
        const motivo_desarquivamento = [
            { value: 'Desarquivada a pedido da Direção/Administrativo', label: 'Desarquivada a pedido da Direção/Administrativo' },
            { value: 'Desarquivada para inclusão de dados do Denunciado/Endereço/Relato', label: 'Desarquivada para inclusão de dados do Denunciado/Endereço/Relato' },
            { value: 'Arquivamento equivocado', label: 'Arquivamento equivocado' },
        ]

        return (
            <>
                <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={{
                        ...this.props.arquivadas,
                        status: "Desarquivada",
                        UsuarioId: this.props.arquivadas?.UsuarioId,
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.UsuarioId) {
                            errors.descricao = 'Campo obrigatório';
                        }
                        if (errors.length > 0) {
                            this.context.addToast({ titulo: "Erro", conteudo: "Verifique os campos obrigatórios e tente novamente." });
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        this.save(values, () => setSubmitting(false));
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => {
                        return (
                            <>
                            
                                <Form>
                                    <FormGroup>
                                        <BSForm.Label as="b">Atendente </BSForm.Label>
                                        <ErrorMessage
                                            name="UsuarioID"
                                            component="span"
                                            className="text-danger small ml-2"
                                        />
                                        <Select
                                            name="UsuarioID"
                                            noOptionsMessage={() => "Nada encontrado."}
                                            placeholder="Pesquisar..."
                                            options={this.state.atendentes}
                                            value={values.UsuarioId ? this.state.atendentes.find(atendente => atendente.value === values.UsuarioId) : ''}
                                            onChange={selectedAtendente => {
                                                setFieldValue('UsuarioId', selectedAtendente ? selectedAtendente.value : null);
                                            }}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <BSForm.Label as="b">Motivo </BSForm.Label>
                                        <ErrorMessage
                                            name="motivo"
                                            component="span"
                                            className="text-danger small ml-2"
                                        />
                                        <Select
                                            name="motivo"
                                            noOptionsMessage={() => "Nada encontrado."}
                                            placeholder="Pesquisar..."
                                            options={motivo_desarquivamento}
                                            value={values.motivo ? motivo_desarquivamento.find(motivo => motivo.value === values.motivo) : ''}
                                            onChange={selectedMotivo => {
                                                const motivoValue = selectedMotivo ? selectedMotivo.value : null;
                                                setFieldValue('motivo', motivoValue);
                                            }}
                                        />
                                    </FormGroup>

                                    <FormGroup className="text-right">
                                        <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                            <FontAwesomeIcon icon={faTimes} />&nbsp;
                                            Fechar
                                        </Button>
                                        <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                            <FontAwesomeIcon icon={faSave} />&nbsp;
                                            Gravar Alterações
                                        </Button>
                                    </FormGroup>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </>
        );
    }
}

export default DenunciasArquivadasModal;