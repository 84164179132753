import React from "react";
import { AppContext } from "../../../../context";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTable, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Request from "../../../../request";
import { Bar, PolarArea, Pie, Doughnut } from "react-chartjs-2";
import Select from "react-select";
import "./index.css"

class ScoreCard extends React.Component {
  static contextType = AppContext;

  state = {
    dataSelecionada: null,
    emDiligencia: 0,
    finalizada: 0,
    encaminhada: 0,
    recebida: 0,
    prazo: [],
    naturezas: [],
    canais: [],

  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let url = `${this.context.config.BACKEND_URL}/denuncia/scorecard`;
    let { dataSelecionada } = this.state;
    if (dataSelecionada) {
      url += `/${dataSelecionada}`;
    }
    Request(
      "GET",
      url,
      this.context.token
    )
      .then((res) => {

        this.setState({
          prazo: res.body.denunciaPrazos,
          emDiligencia: res.body.countEmDiligencia,
          recebida: res.body.countRecebida,
          encaminhada: res.body.countEncaminhada,
          finalizada: res.body.countFinalizada,
          naturezas: res.body.naturezas,
          canais: res.body.canal,
        });
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text:
            "Falha na recuperação da lista de permissões: " + err.toString(),
        });
      });
  }

  handleFilterButtonClick = () => {
    let { dataSelecionada } = this.state;
    console.log("data indo para oo backend:", dataSelecionada)
    if (dataSelecionada) {
      this.fetchData()
    }
  }

  handleResetFilters = () => {
    this.setState(
      {
        dataSelecionada: null,
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {

    const backgroundColors = [
      '#FF6384', // Cor para a primeira natureza
      '#36A2EB', // Cor para a segunda natureza
      '#FFCE56', // Cor para a terceira natureza
      '#4BC0C0', // Cor para a quarta natureza
      '#9966FF', // Cor para a quinta natureza
      '#FF9F40', // Cor para a sexta natureza
      '#C9CBCF', // Cor para a sétima natureza
      '#8D6E63', // Cor para a oitava natureza
      '#D4E157', // Cor para a nona natureza
      '#AB47BC'  // Cor para a décima natureza
    ];


    const optionsData = [
      { label: "ultimaHora", value: "ultimaHora" },
      { label: "ultimas24Horas", value: "ultimas24Horas" },
      { label: "ultimos7Dias", value: "ultimos7Dias" },
      { label: "ultimos30Dias", value: "ultimos30Dias" },
      { label: "ultimos365Dias", value: "ultimos365Dias" }
    ]
    const dataStatus = {
      labels: ['Encaminhadas', 'Recebidas', 'Em Diligência', 'Finalizadas'],
      datasets: [
        {
          data: [
            this.state.encaminhada,
            this.state.recebida,
            this.state.emDiligencia,
            this.state.finalizada
          ],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
        }
      ]
    };
    const naturezaCounts = this.state.naturezas.reduce((acc, denuncia) => {
      const naturezaNome = denuncia.Natureza ? denuncia.Natureza.nome : "Desconhecido";
      acc[naturezaNome] = (acc[naturezaNome] || 0) + 1;
      return acc;
    }, {});

    // Prepara os dados para o gráfico de barras
    const dataNaturezas = {
      labels: Object.keys(naturezaCounts), // Nomes das naturezas
      datasets: [
        {
          label: 'Total de Denúncias',
          data: Object.values(naturezaCounts), // Contagens das naturezas
          backgroundColor: backgroundColors.slice(0, Object.keys(naturezaCounts).length), // Usa apenas as cores necessárias
          hoverBackgroundColor: backgroundColors.slice(0, Object.keys(naturezaCounts).length)
        }
      ]
    };

    const canalCounts = this.state.canais.reduce((acc, denuncia) => {
      const canalNome = denuncia.Canal ? denuncia.Canal.nome : "Desconhecido";
      acc[canalNome] = (acc[canalNome] || 0) + 1;
      return acc;
    }, {});

    // Prepara os dados para o gráfico de pizza
    const dataCanal = {
      labels: Object.keys(canalCounts),
      datasets: [
        {
          data: Object.values(canalCounts),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']
        }
      ]
    };

    return (
      <>
        <Row className="d-flex align-items-center mt-4">
          <Col className="" md={8}>
            <Select
              name="data"
              placeholder="Pesquisar..."
              className="full-width-select"
              isClearable={true}
              menuShouldBlockScroll={true}
              options={optionsData}
              onChange={(selectedOption) => {
                const selectedData = selectedOption ? selectedOption.label : null;
                this.setState({
                  dataSelecionada: selectedData,
                });
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end" md={4}>
            <Button
              className="mx-2"
              variant="primary"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleFilterButtonClick}
            >
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
              Filtrar
            </Button>
            <Button
              className="mr-2"
              variant="danger"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleResetFilters}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
              Limpar
            </Button>
          </Col>
        </Row>

        <Row className="mt-4  linha_cards">
          <Col md={4}>
            <Card className="card-prazo no-prazo">
              <Card.Body>
                <Card.Title>No Prazo</Card.Title>
                <Card.Text>{this.state.prazo.noPrazo}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-prazo prorrogada">
              <Card.Body>
                <Card.Title>Prorrogada</Card.Title>
                <Card.Text>{this.state.prazo.prorrogada}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-prazo atrasada">
              <Card.Body>
                <Card.Title>Atrasada</Card.Title>
                <Card.Text>{this.state.prazo.atrasada}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="d-flex justify-content-between linha_graficos">

          <Col md={5} className="grafico_sombra my-3">
            <div className="text-letf">
              <h3 className="titulo-chart-disquedenuncia">Denúncias por Status</h3>
            </div>
            <div className="chart-container-disquedenuncia">
              <Pie data={dataStatus} />
            </div>
          </Col >

          <Col md={5} className="grafico_sombra my-3">
            <div className="text-left">
              <h3 className="titulo-chart-disquedenuncia">Canais das Denúncias</h3>
            </div>
            <div className="chart-container-disquedenuncia">
              <Pie data={dataCanal} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="grafico_sombra my-3">
            <div className="text-left">
              <h3 className="titulo-chart-disquedenuncia">Natureza das Denúncias</h3>
            </div>
            <div className="chart-container-disquedenuncia-bar">
              <Bar data={dataNaturezas} />
            </div>
          </Col>
        </Row>


      </>
    );
  }
}

export default ScoreCard;
