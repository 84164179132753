import React from "react";
import { AppContext } from "../../../context";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Col,
  Form as BSForm,
  Row,
  FormGroup,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

class ManutencaoForm extends React.Component {
  static contextType = AppContext;

  state = {
    embarcacoes: [],
    showModal: false,
    success: false,
  };

  save(values, callback) {
    const method = this.props.manutencao.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/grupamentoFluvial/manutencao" +
      (this.props.manutencao.id ? "/" + this.props.manutencao.id : "");
    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        this.context.setContent("ManutencaoList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/grupamentoFluvial/embarcacao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ embarcacoes: options.body }));
      })
      .catch(errorHandler);
  }

  render() {
    const resolvido = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const statusManutencao = [
      { value: "Em Andamento", label: "Em Andamento" },
      { value: "Finalizado", label: "Finalizado" },
    ];

    const tipoManutencao = [
      { value: "Corretiva", label: "Corretiva" },
      { value: "Preventiva", label: "Preventiva" },
    ];

    return (
      <Formik
        initialValues={{ ...this.props.manutencao }}
        validate={(values) => {
          const errors = {};

          if (!values.problema_relatado) {
            errors.problema_relatado = "Campo obrigatório";
          }

          if (!values.tipo) {
            errors.tipo = "Campo obrigatório";
          }

          if (!values.doc_protocolo) {
            errors.doc_protocolo = "Campo obrigatório";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, setValues }) => {
          return (
            <>
              <Form>
                    <Card>
                      <Card.Header>
                        <strong>Dados Básicos da Manutenção</strong>
                      </Card.Header>
                      <Card.Body>
                            <Row className="mt-2">
                              <Col lg={4}>
                                <BSForm.Label as="b">
                                  Embarcação{" "}
                                  <span className="text-danger">*</span>
                                </BSForm.Label>
                                <ErrorMessage
                                  name="EmbarcacaoId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="EmbarcacaoId"
                                  menuShouldBlockScroll={true}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  options={this.state.embarcacoes.map(
                                    (embarcacao) => ({
                                      value: embarcacao.id,
                                      label: embarcacao.nome,
                                    })
                                  )}
                                  value={this.state.embarcacoes
                                    .map((embarcacao) => ({
                                      value: embarcacao.id,
                                      label: embarcacao.nome,
                                    }))
                                    .find(
                                      (option) =>
                                        option.value === values.EmbarcacaoId
                                    )}
                                  onChange={(selectedOption) => {
                                    const selectedValue = selectedOption
                                      ? selectedOption.value
                                      : null;
                                    setFieldValue(
                                      "EmbarcacaoId",
                                      selectedValue
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={4}>
                                <BSForm.Label as="b">
                                  DOC/Protoclo{" "}
                                  <span className="text-danger">*</span>
                                </BSForm.Label>
                                <ErrorMessage
                                  name="doc_protocolo"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="doc_protocolo"
                                  className="form-control"
                                />
                              </Col>

                              <Col>
                                <BSForm.Label as="b">Tipo</BSForm.Label>
                                <ErrorMessage
                                  name="tipo"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="tipo"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={tipoManutencao}
                                  value={tipoManutencao.find(
                                    (tipo) => tipo.value === values.tipo
                                  )}
                                  onChange={(option) =>
                                    setFieldValue(`tipo`, option.value)
                                  }
                                />
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col lg={12}>
                                <BSForm.Label as="b">
                                  Problema Relatado
                                </BSForm.Label>
                                <ErrorMessage
                                  name="problema_relatado"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  as = "textarea"
                                  styles={{ height: "200px" }}
                                  type="text"
                                  name="problema_relatado"
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col>
                                <BSForm.Label as="b">Resolvido?</BSForm.Label>
                                <ErrorMessage
                                  name="resolvido"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="resolvido"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={resolvido}
                                  value={resolvido.find(
                                    (res) => res.value === values.resolvido
                                  )}
                                  onChange={(option) =>
                                    setFieldValue(`resolvido`, option.value)
                                  }
                                />
                              </Col>
                              {values.resolvido === "Sim" && (
                                <Col>
                                  <BSForm.Label as="b">
                                    Serviço Realizado
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name="servico_realizado"
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="text"
                                    name="servico_realizado"
                                    className="form-control"
                                  />
                                </Col>
                              )}
                            </Row>
                            <Row className="mt-2">
                              <Col>
                                <BSForm.Label as="b">Status</BSForm.Label>
                                <ErrorMessage
                                  name="status"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="status"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={statusManutencao}
                                  value={statusManutencao.find(
                                    (status) => status.value === values.status
                                  )}
                                  onChange={(option) =>
                                    setFieldValue(`status`, option.value)
                                  }
                                />
                              </Col>
                              <Col>
                                <BSForm.Label as="b">Observação</BSForm.Label>
                                <ErrorMessage
                                  name="observacao"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="observacao"
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                      </Card.Body>
                    </Card>
                <FormGroup className="text-right mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      this.context.setContent("ManutencaoList");
                      setValues({
                        ...values,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Fechar
                  </Button>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default ManutencaoForm;
