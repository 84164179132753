import React from 'react';
import Request from '../../../request';
import { AppContext } from "../../../context";
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';

class VerTodasAsDenunciasList extends React.Component {

    static contextType = AppContext;
    onAction(action, update) {
        switch (action.name) {
            case "edit":
                Request('GET', this.context.config.BACKEND_URL + '/denuncia/verTodas/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("VerTodasAsDenunciasForm", { denuncia: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "print":
                Request('GET', this.context.config.BACKEND_URL + '/denuncia/verTodas/print/' + action.id, this.context.token)
                .responseType('blob')
                .then(res => {
                    const url = window.URL.createObjectURL(res.body);
                    var element = document.createElement('a');
                    element.setAttribute('href', url);
                    element.setAttribute('target', "_blank");

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                })
                .catch(err => {
                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do arquivo: " + err.toString() });
                });
            break;
            default:
        }
    }
    render() {
        return (
            <>
                <h3><FontAwesomeIcon icon={faFolderOpen} /> Ver Todas As Denuncias</h3>
                <Datatable
                    url={this.context.config.BACKEND_URL + '/denuncia/verTodas'}
                    autoSeach={true}
                    onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                    onAction={(action, update) => this.onAction(action, update)}
                    onClickAdd={() => this.context.setContent('VerTodasAsDenunciaForm', { denuncia: {} })}
                />
            </>);
    }
}

export default VerTodasAsDenunciasList;