import React from "react";
import { AppContext } from "../../../context";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Col,
  Form as BSForm,
  Row,
  FormGroup,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

class ProcessoConsepForm extends React.Component {
  static contextType = AppContext;

  state = {
    orgaos: [],
    showModal: false,
    success: false,
  };

  save(values, callback) {
    const method = this.props.processo.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/consep" +
      (this.props.processo.id ? "/" + this.props.processo.id : "");
    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        this.context.setContent("ProcessoConsepList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);
  }

  render() {
    return (
      <Formik
        initialValues={{
          ...this.props.processo,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.numero_processo) {
            errors.numero_processo = "Campo obrigatório";
          }
          if (!values.data_inicial) {
            errors.data_inicial = "Campo obrigatório";
          }
          if (!values.assunto) {
            errors.assunto = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, setValues }) => {
          return (
            <>
              <Form>
                <Col md={12}>
                  <Card className="mt-3">
                    <Card.Header>
                      <strong>Dados Básicos</strong>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md={4} className="mt-2">
                          <BSForm.Label>Nº do Processo</BSForm.Label>

                          <span className="text-danger">*</span>
                          <ErrorMessage
                            name="numero_processo"
                            component="span"
                            className="text-danger small ml-2"
                          />
                          <Field
                            type="text"
                            name="numero_processo"
                            className="form-control"
                          />
                        </Col>
                        <Col md={4} className="mt-2">
                          <BSForm.Label>Data Inicial</BSForm.Label>

                          <span className="text-danger">*</span>
                          <ErrorMessage
                            name="data_inicial"
                            component="span"
                            className="text-danger small ml-2"
                          />
                          <Field
                            type="date"
                            name="data_inicial"
                            className="form-control"
                            value={values.data_inicial}
                          />
                        </Col>

                        <Col md={4} className="mt-2">
                          <BSForm.Label>Data Final</BSForm.Label>
                          <Field
                            type="date"
                            name="data_final"
                            className="form-control"
                            value={values.data_final}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} className="mt-2">
                          <BSForm.Label>Assunto</BSForm.Label>
                          <span className="text-danger">*</span>
                          <ErrorMessage
                            name="assunto"
                            component="span"
                            className="text-danger small ml-2"
                          />
                          <Field
                            type="text"
                            name="assunto"
                            className="form-control"
                          />
                        </Col>

                        <Col md={4} className="mt-2">
                          <BSForm.Label>Orgão</BSForm.Label>
                          <ErrorMessage
                            name="OrgaoId"
                            component="span"
                            className="text-danger small ml-2"
                          />
                          <Select
                            name="OrgaoId"
                            noOptionsMessage={() => "Nada encontrado."}
                            placeholder="Pesquisar..."
                            options={this.state.orgaos}
                            value={
                              this.state.orgaos
                                ? this.state.orgaos.find(
                                    (option) => option.value === values.OrgaoId
                                  )
                                : ""
                            }
                            onChange={(option) => {
                              setFieldValue(
                                "OrgaoId",
                                option ? option.value : null
                              );
                            }}
                          />
                        </Col>

                        <Col md={4} className="mt-2">
                          <BSForm.Label>Documento</BSForm.Label>
                          <Field
                            type="text"
                            name="documento"
                            className="form-control"
                            value={values.documento}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} className="mt-2">
                          <BSForm.Label>Resolução</BSForm.Label>
                          <Field
                            type="text"
                            name="resolucao"
                            className="form-control"
                            value={values.resolucao}
                          />
                        </Col>

                        <Col md={4} className="mt-2">
                          <BSForm.Label>Relator</BSForm.Label>
                          <Field
                            as="textarea"
                            styles={{ height: "200px" }}
                            type="text"
                            name="problema_relatado"
                            className="form-control"
                            value={values.problema_relatado}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} className="mt-2">
                          <BSForm.Label>Julgamento</BSForm.Label>
                          <Field
                            type="text"
                            name="documento"
                            className="form-control"
                            value={values.julgamento}
                          />
                        </Col>

                        <Col md={4} className="mt-2">
                          <BSForm.Label>Observação</BSForm.Label>
                          <Field
                            as="textarea"
                            type="text"
                            name="observacao"
                            className="form-control"
                            value={values.observacao}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <FormGroup className="text-right mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      this.context.setContent("ProcessoConsepList");
                      setValues({
                        ...values,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Fechar
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default ProcessoConsepForm;
