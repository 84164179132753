import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import DenunciaApensoModal from './DenunciaApensoModal';


class DenunciaApensoList extends React.Component {

    static contextType = AppContext;

    render(){
        return (<>
            <ModelList 
                  url={this.context.config.BACKEND_URL + '/denuncia/apenso'}
                title="Lista de Denuncia"
                modelName="apenso"
                modelIcon="faPaperclip"
                modelForm={DenunciaApensoModal}
                useAdd={false}
            />
        </>);
    }
}


export default DenunciaApensoList;