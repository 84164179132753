import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup, Tabs, Tab } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AppContext } from "../../../context";

class UsuarioSetorForm extends React.Component {
  static contextType = AppContext;

  efetivar(values, callback) {
    const method = "POST";
    const url = this.context.config.BACKEND_URL + "/usuario/efetivar";

    const payload = {
      usuario_entrando_nome: values.usuario_entrando_nome,
      usuario_entrando_login: values.usuario_entrando_login,
      usuario_entrando_email: values.usuario_entrando_email,
      perfilId: values.perfilId,
      orgaoId: values.orgaoId,
      setorId: values.setorId,
      id:values.id,
      idSaindo: values.idSaindo,
    };

    Request(method, url, this.context.token)
      .send(payload)
      .then((res) => {
        this.context.addToast({
          titulo: "Sucesso",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  recusar(values, callback) {
    const method = "POST";
    const url = this.context.config.BACKEND_URL + "/usuario/recusar";

    const payload = { id: this.props.usuario.id }; // Envia apenas o ID

    Request(method, url, this.context.token)
      .send(payload)
      .then((res) => {
        this.context.addToast({
          titulo: "Sucesso",
          conteudo: "Registro recusado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          id:this.props.usuario.id,
          idSaindo: this.props.usuario.Usuario?.id || '',
          usuario_entrando_nome: this.props.usuario.usuario_entrando_nome || "",
          usuario_entrando_login:
            this.props.usuario.usuario_entrando_login || "",
          usuario_entrando_email:
            this.props.usuario.usuario_entrando_email || "",
          usuario_saindo: this.props.usuario.Usuario,
          orgaoId: this.props.usuario.OrgaoId,
          setorId: this.props.usuario.SetorId,
          perfilId: this.props.usuario.Usuario?.Perfis?.[0]?.id || "", // Pega o primeiro id do perfil
        }}
        validate={(values) => {
          const errors = {};
          if (!values.usuario_entrando_nome) {
            errors.usuario_entrando_nome = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.efetivar(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Tabs defaultActiveKey="usuario_entrando" className="mb-2">
              <Tab eventKey="usuario_entrando" title="Usuário Entrando">
                <FormGroup>
                  <BSForm.Label>Nome</BSForm.Label>
                  <ErrorMessage
                    name="usuario_entrando_nome"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="usuario_entrando_nome"
                    className="form-control"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Login</BSForm.Label>
                  <ErrorMessage
                    name="usuario_entrando_login"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="usuario_entrando_login"
                    className="form-control"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Email</BSForm.Label>
                  <ErrorMessage
                    name="usuario_entrando_email"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="email"
                    name="usuario_entrando_email"
                    className="form-control"
                    disabled
                  />
                </FormGroup>
              </Tab>

              <Tab eventKey="usuario_saindo" title="Usuário Saindo">
                <div>
                  <FormGroup>
                    <BSForm.Label>Nome</BSForm.Label>
                    <Field
                      type="text"
                      name={`usuario_saindo.nome`}
                      className="form-control"
                      disabled
                    />
                    <ErrorMessage
                      name={`usuario_saindo.nome`}
                      component="span"
                      className="text-danger small ml-2"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Login (CPF)</BSForm.Label>
                    <Field
                      type="text"
                      name={`usuario_saindo.login`}
                      className="form-control"
                      disabled
                    />
                    <ErrorMessage
                      name={`usuario_saindo.login`}
                      component="span"
                      className="text-danger small ml-2"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Email</BSForm.Label>
                    <Field
                      type="email"
                      name={`usuario_saindo.email`}
                      className="form-control"
                      disabled
                    />
                    <ErrorMessage
                      name={`usuario_saindo.email`}
                      component="span"
                      className="text-danger small ml-2"
                    />
                  </FormGroup>
                </div>
              </Tab>
            </Tabs>

            <FormGroup className="text-right">
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="primary"
                className="ml-2"
              >
                <FontAwesomeIcon icon={faSave} />
                &nbsp; Efetivar
              </Button>

              <Button
                type="button"
                disabled={isSubmitting}
                variant="danger"
                className="ml-2"
                onClick={() => this.recusar(values, () => {})} // Chama recusar diretamente
              >
                <FontAwesomeIcon icon={faUndo} />
                &nbsp; Recusar
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

export default UsuarioSetorForm;
