import React from "react";

export const defaultValues = {
  token: null,
  usuario: null,
  modulo: null,
  fullpage: true,
  conteudo: "LoginForm",
  contentProps: {},
  toasts: [],
  menus: [],
  modal: {
    show: false,
    titulo: null,
    conteudo: null,
    onClose: null,
    size: "md",
  },
  titulo: "PISP - Plataforma Integrada de Segurança Pública",
  config: {
   FRONTEND_URL: 'https://pisp.segup.pa.gov.br',
   BACKEND_URL: 'https://pisp.segup.pa.gov.br:8080',
    CONFIGURE_URL: 'https://pisp.segup.pa.gov.br:8080/configuracao/frontend'
    // FRONTEND_URL: "http://localhost:3000",
    // BACKEND_URL: "http://localhost:8080",
    //  CONFIGURE_URL: "http://localhost:8080/configuracao/frontend",
  },
  toastKey: 1,
  expirado: false,
  setState: (state) => undefined,
  setConfig: (config) => undefined,
  delToast: (key) => undefined,
  addToast: ({ titulo, conteudo, delay }) => undefined,
  setToken: (token) => undefined,
  resetToken: () => undefined,
  setModule: (moduleName) => undefined,
  setContent: (conteudo, props, titulo, fullpage) => undefined,
  setTitle: (titulo) => undefined,
  openModal: ({ show, titulo, conteudo, onClose, size }) => undefined,
  closeModal: () => undefined,
};

export const AppContext = React.createContext(defaultValues);

export const createContextValue = (currentState, setState) => ({
  ...currentState,
  setState: (state) => setState(state),
  setConfig: (config) => setState({ config }),
  delToast: (key) =>
    setState((state) => ({
      toasts: state.toasts.filter((toast) => toast.toastKey !== key),
    })),
  addToast: ({ titulo, conteudo, delay }) =>
    setState((state) => ({
      toastKey: state.toastKey + 1,
      toasts: [
        ...state.toasts,
        { titulo, conteudo, delay, toastKey: state.toastKey },
      ],
    })),
  setToken: (token, usuario) =>
    setState({
      token,
      usuario,
      conteudo: "DashboardPage",
      modulo: false,
      menus: [],
    }),
  setModule: (moduleName) =>
    setState((state) => {
      const modulo = state.usuario.Modulos.find(
        (modulo) => modulo.nome === moduleName
      );
      return {
        conteudo: modulo.conteudo,
        menus: modulo.Menus,
        modulo: moduleName,
        titulo: modulo.titulo,
        fullpage: false,
      };
    }),
  resetToken: () =>
    setState({
      ...defaultValues,
      toasts: [
        {
          titulo: "Usuário desconectado",
          conteudo: "Feche a janela do navegador para sair com segurança.",
          toastKey: 1,
        },
      ],
      toastKey: 2,
    }),
  setContent: (conteudo, props, titulo, fullpage = false) =>
    setState((state) => ({
      conteudo,
      contentProps: props,
      titulo: titulo ?? state.titulo,
      fullpage,
    })),
  setTitle: (titulo) => setState({ titulo }),
  openModal: ({ show, titulo, conteudo, onClose, size, scrollable }) =>
    setState({
      modal: {
        ...defaultValues.modal,
        show: show ?? true,
        scrollable,
        titulo,
        conteudo,
        onClose,
        size,
      },
    }),
  closeModal: () =>
    setState({
      modal: defaultValues.modal,
    }),
  isAuthorized: (permissionName) =>
    currentState.usuario.Permissoes.find((p) => p.nome === permissionName),
});
