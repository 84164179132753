import {
  faDownload,
  faExclamationCircle,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Col,
  Form as BSForm,
  FormGroup,
  Row,
  Tab,
  Table,
  Tabs,
  FormControl,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Map from "./Map.js";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { AppContext } from "../../../context";
import moment from "moment";
import CustomEditor from "../../Editor.js";

class DenunciasRegistradasForm extends React.Component {
  static contextType = AppContext;
  state = {
    cidades: [],
    bairros: [],
    canais: [],
    denuncias: [],
    orgaos: [],
  };

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/canal/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ canais: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/natureza/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ naturezas: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/bairro/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ bairros: options.body }));
      })
      .catch(errorHandler);
  }

  render() {
    const options = [
      { value: "Encaminhada", label: "Encaminhada" },
      { value: "Recebida", label: "Recebida" },
      { value: "Em Diligencia", label: "Em Diligencia" },
      { value: "Finalizada", label: "Finalizada" },
    ];

    const center =
      this.props.acompanhar.latitude && this.props.acompanhar.longitude
        ? [this.props.acompanhar.latitude, this.props.acompanhar.longitude]
        : [-1.4631897998991463, -48.4955653127071868]; // ou um valor padrão apropriado
    const logradouro = this.props.acompanhar.logradouro;
    const numero = this.props.acompanhar.numero ?? null;

    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          ...this.props.acompanhar,
          Anexos: this.props.acompanhar?.Anexos ?? [],
          Apensos: this.props.acompanhar?.Apensos ?? [],
          CidadeId: this.props.acompanhar?.CidadeId ?? [],
          SetorId: this.props.acompanhar?.SetorId ?? [],
          BairroId: this.props.acompanhar?.BairroId,
          NaturezaId: this.props.acompanhar?.NaturezaId,
          relacionamentos: this.props.acompanhar?.relacionamentos ?? [],
          UsuarioId:
            this.props.acompanhar?.UsuarioId ?? this.context.usuario.id,
          urgente: this.props.acompanhar?.urgente ?? false,
          copia: this.props.acompanhar?.copia ?? null,
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <></>
              <Form>
                <Tabs className="mb-3" defaultActiveKey="dados-basicos">
                  <Tab
                    eventKey="dados-basicos"
                    title={
                      <span style={{ fontWeight: "bold" }}>
                        DENUNCIA: {this.props.acompanhar.id}
                      </span>
                    }
                  >
                    <FormGroup>
                      <Row>
                        <Col md={6} className="d-flex flex-column">
                          <Row>
                            <Col md={12}>
                              <small>
                                Arraste o mapa ou clique no botão de pesquisa
                                para encontrar um endereço.
                              </small>
                            </Col>
                          </Row>
                          <Row className="flex-grow-1">
                            <Col md="12">
                              <Map
                                center={center}
                                logradouro={logradouro}
                                numero={numero}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <BSForm.Label as="b">Latitude</BSForm.Label>

                              <Field
                                type="text"
                                name="latitude"
                                className="form-control"
                                value={values.latitude}
                                readOnly
                              />
                            </Col>
                            <Col>
                              <BSForm.Label as="b">Longitude</BSForm.Label>

                              <Field
                                type="text"
                                name="longitude"
                                className="form-control"
                                value={values.longitude}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <BSForm.Label as="b">
                                  Canal<span className="text-danger">*</span>
                                </BSForm.Label>
                                <ErrorMessage
                                  name="CanalId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="CanalId"
                                  isDisabled={true}
                                  value={
                                    this.state.canais
                                      ? this.state.canais.find(
                                          (option) =>
                                            option.value === values.CanalId
                                        )
                                      : ""
                                  }
                                />
                              </Col>
                              <Col lg={8}>
                                <BSForm.Label as="b">Natureza</BSForm.Label>

                                <Select
                                  name="NaturezaId"
                                  isDisabled={true}
                                  value={
                                    this.state.naturezas
                                      ? this.state.naturezas.find(
                                          (option) =>
                                            option.value === values.NaturezaId
                                        )
                                      : ""
                                  }
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {values.descricao_original != null ? (
                            <FormGroup>
                              <BSForm.Label as="b">
                                Descrição Original
                              </BSForm.Label>
                              <Field
                                readOnly
                                as="textarea"
                                name="descricao_original"
                                className="form-control"
                                value={values.descricao_original}
                                style={{ height: "200px" }}
                              />
                            </FormGroup>
                          ) : null}
                          <FormGroup>
                            <BSForm.Label as="b">
                              Descrição<span className="text-danger">*</span>
                            </BSForm.Label>
                            <ErrorMessage
                              name="descricao"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <CustomEditor
                             readOnly={true}
                              name="descricao"
                              value={values.descricao}
                              setFieldValue={setFieldValue}

                            />
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="9">
                                <BSForm.Label as="b">Logradouro</BSForm.Label>

                                <Field
                                  type="text"
                                  name="logradouro"
                                  className="form-control"
                                  readOnly
                                  value={values.logradouro}
                                />
                              </Col>
                              <Col md="3">
                                <BSForm.Label as="b">Número</BSForm.Label>
                                <Field
                                  type="text"
                                  name="numero"
                                  className="form-control"
                                  readOnly
                                  value={values.numero}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="6">
                                <BSForm.Label as="b">Cidade</BSForm.Label>

                                <Select
                                  name="CidadeId"
                                  isDisabled
                                  value={
                                    this.state.cidades
                                      ? this.state.cidades.find(
                                          (option) =>
                                            option.value === values.CidadeId
                                        )
                                      : ""
                                  }
                                />
                              </Col>
                              <Col md="6">
                                <BSForm.Label as="b">Bairro</BSForm.Label>

                                <CreatableSelect
                                  name="BairroId"
                                  isDisabled
                                  value={this.state.bairros.find(
                                    (bairro) => bairro.value === values.BairroId
                                  )}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <BSForm.Label as="b">Estabelecimento</BSForm.Label>
                            <Field
                              type="text"
                              name="estabelecimento"
                              className="form-control"
                              readOnly
                              value={values.estabelecimento}
                            />
                          </FormGroup>
                          <Row>
                            <Col lg={6}>
                              <BSForm.Label as="b">Referência</BSForm.Label>
                              <Field
                                type="text"
                                name="referencia"
                                className="form-control"
                                readOnly
                                value={values.referencia}
                              />
                            </Col>
                            <Col lg={6}>
                              <BSForm.Label as="b">Status</BSForm.Label>
                              <Select
                                name="status"
                                options={options}
                                isDisabled
                                value={options.find(
                                  (option) => option.value === values.status
                                )}
                                onChange={(selectedOption) => {
                                  const selectedValue = selectedOption
                                    ? selectedOption.value
                                    : null;
                                  setFieldValue("status", selectedValue);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col lg={3}>
                              <div
                                style={{
                                  backgroundColor: "#ff6947",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  name="urgente"
                                  disabled
                                  className="text-right"
                                />
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                <BSForm.Label
                                  as="b"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Urgente
                                </BSForm.Label>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Tab>
                  <Tab eventKey="anexos" title="Anexos">
                    <Table striped size="sm" responsive className="mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th>Nome</th>
                          <th style={{ width: 120, textAlign: "center" }}>
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Anexos.map((anexo, key) => (
                          <tr key={key}>
                            <td>{anexo.nome ?? ""}</td>
                            <td className="text-center">
                              {anexo.id ? (
                                <Button
                                  as="a"
                                  href={`${this.context.config.BACKEND_URL}/anexo/disquedenuncia/${anexo.id}/${anexo.uuid}`}
                                  target="_blank"
                                  download={anexo.nome}
                                  size="sm"
                                  variant="info"
                                  title="Download"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                  ></FontAwesomeIcon>
                                </Button>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4">
                            <ErrorMessage
                              name="Anexos"
                              component="span"
                              className="text-danger small ml-2"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Tab>
                  {this.props.acompanhar?.Apensos?.length > 0 ? (
                    <Tab eventKey="apensos" title="Apenso">
                      {values.Apensos &&
                        values.Apensos.map((apenso, index) => (
                          <Row key={index} className="mb-2">
                            <Col>
                              <BSForm.Label as="b">
                                Apenso #{index + 1}
                              </BSForm.Label>
                              <Field
                                as="textarea"
                                type="text"
                                disabled={true}
                                name={`Apensos[${index}].conteudo`}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        ))}
                    </Tab>
                  ) : null}
                </Tabs>
                <FormGroup className="text-right">
                  <Button
                    type="button"
                    variant="primary"
                    className="mt-2 ml-2"
                    onClick={() =>
                      this.context.setContent("DenunciasRegistradasList")
                    }
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                    &nbsp; Retornar
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default DenunciasRegistradasForm;
