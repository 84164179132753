import React from 'react';
import { AppContext } from '../../../context';
import { faShip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';
import Request from '../../../request';

class EmbarcacaoList extends React.Component {    

    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name){
            case "edit":
                Request('GET', this.context.config.BACKEND_URL + '/grupamentoFluvial/embarcacao/' + action.id, this.context.token)
                .then(res => {
                    this.context.setContent("GrupamentoFluvialForm", {embarcacao: res.body});
                })
                .catch(err => {
                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                });
                break;
            case "delete":
                if (window.confirm("Deseja realmente excluir este registro?"))
                {
                    Request('DELETE', this.context.config.BACKEND_URL + '/grupamentoFluvial/embarcacao/' + action.id, this.context.token)
                    .then(res => {
                        callback();
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na exclusão do registro: " + err.toString() });
                    });
                }
                break;
            default:
                break;                  
        }
    }
    

    render(){
        return (<>
            <h3><FontAwesomeIcon icon={faShip}/> Lista de Embarcações</h3>
            <hr />
            <Datatable 
                url={this.context.config.BACKEND_URL + '/grupamentoFluvial/embarcacao'}                
                autoSeach={true}
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'grupamentoFluvial_admin')}
                onError={(err) => this.context.addToast({titulo: "Erro", conteudo: err.toString()})}
                onAction={(action, update) => this.onAction(action, update)}
                onClickAdd={ () => this.context.setContent('GrupamentoFluvialForm', {embarcacao: {}})}
            />
        </>);
    }
}

export default EmbarcacaoList;

