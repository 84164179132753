import ProcessoConsepForm from "./ProcessoConsepForm";
import ProcessoConsepList from "./ProcessoConsepList";


const ConsepContents = {
  ProcessoConsepForm,
  ProcessoConsepList,
};

export default ConsepContents;
