import React from 'react';
import Request from '../../../request';
import { AppContext } from "../../../context";
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';
import { Col, Row } from 'react-bootstrap';

class AcompanharDenunciasList extends React.Component {

    static contextType = AppContext;

    updateDatatable = () => {
        this.refs.datatable.updateDatatable(); 
    }

    onAction(action) {
        switch (action.name) {
            case "class":
                Request('GET', this.context.config.BACKEND_URL + '/denuncia/acompanhar/' + action.id, this.context.token)
                    .then(res => {
                        const denuncia = res.body;
                        this.context.setContent("AcompanharDenunciasForm", { acompanhar: denuncia });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            default:
        }
    }

    render() {
        return (
            <>
                <h3><FontAwesomeIcon icon={faFolderOpen} /> Acompanhar Denúncias</h3>
                <h6>
                    <strong> Prazo de resposta das Denuncias
                        <a
                            className="btn btn-link-dark"
                            target="_blank"
                            rel="noreferrer"
                            href={`${this.context.config.FRONTEND_URL}/doc/DECRETO_N1.253_REGIMENTO_INTERNO_DISQUE_DENUNCIA.pdf`}
                            download="DECRETO_N1.253_REGIMENTO_INTERNO_DISQUE_DENUNCIA.pdf"
                        >
                            (Art 6º do Decreto Nº 1.253/2020)
                        </a>
                    </strong>
                </h6>
                <Row>
                    <Col>
                        <span style={{ display: 'inline-block', width: '85px', height: '35px', marginBottom:"5px",marginRight:"5px",  backgroundColor: '#0ee824', padding: '5px 5px' }}>No Prazo</span>
                        Do despacho até 15º dia
                    </Col>
                    <Col>
                        <span style={{ display: 'inline-block', width: '85px', height: '35px', marginBottom:"5px",marginRight:"5px",  backgroundColor: '#ffff00', padding: '5px 5px' }}>Prorrogada</span>
                        Do 16º dia ao 25º dia
                    </Col>
                    <Col>
                        <span style={{ display: 'inline-block', width: '85px', height: '35px', marginBottom:"5px",marginRight:"5px",  backgroundColor: '#d61515', color: "white", padding: '5px 5px' }}>Atrasada</span>
                        26º dia em diante
                    </Col>


                </Row>
                <Datatable
                    ref="datatable"
                    url={this.context.config.BACKEND_URL + '/denuncia/acompanhar'}
                    autoSeach={true}
                    onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                    onAction={(action, update) => this.onAction(action, update)}
                    updateDatatable={this.updateDatatable}
                />
            </>
        );
    }
}

export default AcompanharDenunciasList;
