import React from 'react';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import './index.css';

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);

    let editorState;

    if (props.value) {
      const blocksFromHtml = htmlToDraft(props.value);
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        editorState = EditorState.createWithContent(contentState);
      } else {
        editorState = EditorState.createEmpty();
      }
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      editorState,
    };
  }

  onEditorStateChange = (editorState) => {
    // Se não estiver em modo de leitura, permite a atualização
    if (!this.props.readOnly) {
      this.setState({
        editorState,
      });

      const contentState = editorState.getCurrentContent();
      const htmlContent = draftToHtml(convertToRaw(contentState));

      this.props.setFieldValue(this.props.name, htmlContent);
    }
  };

  uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve({ data: { link: event.target.result } });
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); // Converte o arquivo em Base64 para exibição no editor
    });
  };

  render() {
    const imagePlugin = createImagePlugin();
    const plugins = [imagePlugin];
    const { editorState } = this.state;
    const { readOnly } = this.props; // Define readOnly como prop

    return (
      <Editor
        plugins={plugins}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        toolbarClassName="toolbar-class"
        onEditorStateChange={this.onEditorStateChange}
        readOnly={readOnly || false} // Define readOnly como opcional
        toolbarHidden={readOnly || false} // Oculta a toolbar no modo de leitura
        toolbar={{
          image: {
            uploadCallback: this.uploadImageCallback, // Função para upload de imagem
            alt: { present: true, mandatory: false },
            previewImage: true,
          },
        }}
      />
    );
  }
}

export default CustomEditor;
