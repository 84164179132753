import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import ObservacaoUsuarioForm from "./ObservacaoUsuarioForm";
import { Alert } from 'react-bootstrap';

class ObservacaoUsuarioList extends React.Component {
  static contextType = AppContext;

  state = {
    avisoObservacao: null, // Para armazenar mensagens de aviso
  };

  // Método para lidar com ações do ModelList
  onAction = async (action, update, defaultHandler) => {
    if (action.name === 'edit') {
      const hasObservacao = await this.verificarObservacao(action.id);
      
      if (hasObservacao) {
        // Exibir mensagem de aviso se já existir uma observação "sim"
        this.setState({
          avisoObservacao: 'Este usuário já possui uma observação registrada.',
        });
      } else {
        // Limpa o aviso e chama o handler padrão para abrir o formulário de edição
        this.setState({ avisoObservacao: null });
        defaultHandler(); // Continua com o fluxo padrão
      }
    } else {
      // Para outras ações, use o comportamento padrão
      defaultHandler();
    }
  };

  // Método para verificar se já existe uma observação "sim"
  verificarObservacao = async (usuarioId) => {
    try {
      const response = await fetch(
        `${this.context.config.BACKEND_URL}/observacao/${usuarioId}`,
        {
          headers: {
            Authorization: `Bearer ${this.context.token}`,
          },
        }
      );

      // Se retornar 403, significa que já existe uma observação "sim"
      return response.status === 403;
    } catch (error) {
      console.error('Erro ao verificar observação:', error);
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.state.avisoObservacao && (
          <Alert variant="warning" className="mb-3">
            {this.state.avisoObservacao}
          </Alert>
        )}
        <ModelList
          url={this.context.config.BACKEND_URL + '/observacao'}
          title="Lista de Observações"
          modelIcon="faUsers"
          modelName="observacao"
          modelForm={ObservacaoUsuarioForm}
          onAction={this.onAction}
        />
      </div>
    );
  }
}

export default ObservacaoUsuarioList;
